<template>
  <div class="main-body">
    <div class="table-operator">
      <a-button type="primary" @click="addPage()">创建页面</a-button>
    </div>
    <div class="page-attention">
      <p>自定义H5页面、组件化装修页面、方便快捷。</p>
    </div>
    <a-table
      class="doc-table"
      :dataSource="dataSource"
      :columns="columns"
      :pagination="false"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'name'">
          <a-avatar
            src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
          />
          <a-button type="text">五佳网络</a-button>
        </template>
        <template v-if="column.key === 'vsersion_name'">
          {{ record.vsersion_name }}【{{ record.version }}】
        </template>
        <template v-if="column.key === 'online'">
          <span v-if="record.online == 1" style="color: #b7eb8f"> 已发布 </span>
          <span v-else style="color: #ffe58f">未发布</span>
        </template>
        <template v-if="column.key === 'action'">
          <a-button type="primary" size="small">查看</a-button>
        </template>
      </template>
    </a-table>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted } from "vue";
import * as Api from "@/addons/client/api";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const router = useRouter();

    const dataSource = ref();
    const onLoadData = () => {
      Api.list().then((res) => {
        dataSource.value = res.data;
      });
    };

    const addPage = () => {
      router.push("/h5web/page/design");
    };

    // 初始化获取左侧导航
    onMounted(() => {
      onLoadData();
    });

    return {
      addPage,
      dataSource,
      columns: [
        {
          title: "页面ID",
          dataIndex: "app_id",
          key: "app_id",
          width: "10%",
        },
        {
          title: "页面名称",
          dataIndex: "app_name",
          key: "app_name",
          width: "10%",
        },
        {
          title: "页面类型",
          dataIndex: "app_type",
          key: "app_type",
          width: "20%",
        },
        {
          title: "添加时间",
          dataIndex: "vsersion_name",
          key: "vsersion_name",
          width: "10%",
        },
        {
          title: "状态",
          dataIndex: "online",
          key: "online",
          width: "10%",
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          width: "10%",
        },
      ],
    };
  },
});
</script>